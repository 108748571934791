import { clsx, type ClassValue } from "clsx";
import { subDays } from "date-fns";
import parse from "html-react-parser";
import { DateRange } from "react-day-picker";
import sanitizeHtml from "sanitize-html";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function extractDate(date: Date | undefined) {
  if (!date) {
    throw new Error("Date is undefined");
  }

  return new Date(date).toLocaleDateString("en-CA");
}

export function htParse(html: string) {
  let parsed;

  try {
    parsed = parse(
      sanitizeHtml(html).replace(
        "<ul>",
        '<ul className="list-disc space-y-3">',
      ) ?? "",
    );
  } catch (error) {
    parsed = "HTML parsing failed.";
  }

  return parsed;
}

export function stringToDateRange(dateString: string): DateRange {
  const date = new Date();
  switch (dateString) {
    case "1 Week":
      return {
        from: subDays(date, 7),
        to: date,
      };
    case "1 Month":
      return {
        from: subDays(date, 30),
        to: date,
      };
    case "3 Months":
      return {
        from: subDays(date, 90),
        to: date,
      };
    case "6 Months":
      return {
        from: subDays(date, 180),
        to: date,
      };
    case "1 Year":
      return {
        from: subDays(date, 365),
        to: date,
      };
    case "5 Years":
      return {
        from: subDays(date, 1825),
        to: date,
      };
    case "10 Years":
      return {
        from: subDays(date, 3650),
        to: date,
      };
    default:
      return {
        from: subDays(date, 90),
        to: date,
      };
  }
}
